import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

const buttonVariants = cva(
  "inline-flex gap-1 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-white shadow hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline p-0",
        avatar: "hover:ring-2 hover:ring-ring ring-offset-4",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9 rounded-full",
        link: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const SparkleEffect = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M9.937 15.5A2 2 0 0 0 8.5 14.063l-6.135-1.582a.5.5 0 0 1 0-.962L8.5 9.936A2 2 0 0 0 9.937 8.5l1.582-6.135a.5.5 0 0 1 .963 0L14.063 8.5A2 2 0 0 0 15.5 9.937l6.135 1.581a.5.5 0 0 1 0 .964L15.5 14.063a2 2 0 0 0-1.437 1.437l-1.582 6.135a.5.5 0 0 1-.963 0z" />
    </svg>
  );
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export const ShinyButton = ({
  children,
  className,
  onMouseEnter,
  shinyUntil,
  ...rest
}: React.ComponentPropsWithoutRef<typeof Button> & {
  shinyUntil: number;
}) => {
  if (new Date().getTime() > shinyUntil)
    return (
      <Button onMouseEnter={onMouseEnter} className={className} {...rest}>
        {children}
      </Button>
    );

  const sparkleColor =
    (rest.variant ?? "default") === "default" ?
      "stroke-brand-100"
    : "stroke-brand-700";

  return (
    <Button
      className={cn(
        "relative overflow-hidden after:absolute after:-left-32 after:-top-4 after:-bottom-4 after:animate-shine-right after:delay-300 after:bg-brand-300 after:opacity-50 after:w-24",
        "hover:after:bg-white hover:after:animate-shine-right-2 hover:after:delay-150 hover:after:opacity-50 after:-skew-x-12 after:blur-lg",
        className,
      )}
      onMouseEnter={(e) => {
        const c = "after:animate-shine-right";
        const target = e.currentTarget as HTMLButtonElement;
        target.classList.remove(c);
        setTimeout(() => target.classList.add(c), 100);

        target.querySelectorAll(".animate-sparkle").forEach((el) => {
          el.classList.remove("animate-sparkle");
          setTimeout(() => el.classList.add("animate-sparkle"), 100);
        });
        onMouseEnter?.(e);
      }}
      {...rest}
    >
      <SparkleEffect
        className={cn(
          "absolute left-4 top-2 opacity-0 size-2 animate-sparkle",
          sparkleColor,
        )}
      />
      <SparkleEffect
        className={cn(
          "absolute right-4 bottom-2 opacity-0 size-3 animate-sparkle delay-500",
          sparkleColor,
        )}
      />
      {children}
    </Button>
  );
};

export { Button, buttonVariants };
